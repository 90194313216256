import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Discount from '../../components/discount'
import PublicBottom from '../../components/publicBottom'
import PublicTabber from '../../components/publicTabber'
export default function ProductEntrance(props) {	
	useEffect(()=>{
		window.scrollTo(0, 0);
	},[])
	let openLogin = ()=>{
        window.open("https://zoe-cloud.com/web/",'_blank');  
    }
	return (
		<div className="productEntrance">
			<PublicTabber type='1' path='productservice' />
			<div className='productEntrancemain'>
				<div className='bannerBox'>
					<div className='banner'>
						<img src={require('./img/gfjdhnortyhjrnbgfjgf.png')} alt="" />
						<div className='content'>
							<div className='title'>
								产品入口
							</div>
							<div className='text'>
								<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} />
								5分钟快速拥有自己的小程序
							</div>
						</div>
					</div>
				</div>
				<div className='productEntrancecontent'>
					<div className='productEntrancecontentitem'>
						<img src={require('./img/SketchPng2bff5fc28249ac1bb8dd3286ed6fcdad503b89d42ed6b459db17238e398e6b2e.png')} />
						<div className='title'>众宜服务中心</div>
						<div className='main'>
							快速体验产品
							<br/>
							搭建专属小程序
							<br/>
							开启私域专属流量池
						</div>
						<img className='code' src={require('./img/f9b8f55ea8d547288777e5cf516b1da6_mergeImage.png')} alt="" />
						{/* <div className='clickItem'>查看版本记录</div> */}
					</div>
					<div className='productEntrancecontentitem'>
						<img src={require('./img/SketchPng76d264d999ef668d7b9d3d9ef413b0875f599d6c106b825198b21fce36370145.png')} />
						<div className='title'>众宜App</div>
						<div className='main'>
						手机移动端
							<br/>
							扫码下载 
							<br/>
							完成专属小程序配置
						</div>
						<img className='code' src={require('./img/9aa456c16f1249c58cd29a8e24e9db00_mergeImage.png')} alt="" />
						{/* <div className='clickItem'>查看版本记录</div> */}
					</div>
					<div className='productEntrancecontentitem'>
						<img src={require('./img/SketchPnge7c1b9329ccd1c0ecbd69b5f2ecac89ecdeeec715d62b7ab39d11e93a868da8b.png')} />
						<div className='title'>众宜电脑端</div>
						<div className='main'>
						电脑端轻松操作
							<br/>
							多款商品上传
							<br/>
							轻松录入全部内容
						</div>
						<div className='mainin' onClick={openLogin}>
							<img src={require('./img/SketchPng46c98371c04795eeb6bb6e680d79efce04de6625d4f836427d9b8a350663d90b.png')} alt="" />
						</div>
						{/* <div className='clickItem'>查看版本记录</div> */}
					</div>
				</div>
			</div>
			
			<Discount />
			<PublicBottom />
		</div>
	)
}