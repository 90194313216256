import axios from "axios"

let baseUrl

if (process.env.NODE_ENV == "production") {
  baseUrl = 'https://merchant.zoe-cloud.com/'
} else if (process.env.NODE_ENV == "development") {
  baseUrl = "api"
} else if (process.env.NODE_ENV == "test") {
  baseUrl = "api"
}

const request = axios.create({
  baseURL: baseUrl, //基准地址
  timeout: 50000,
})

// 请求拦截
request.interceptors.request.use(config => {
 

  /* 	header['appVersion'] = '1.3.014'
	header['platform'] = phoneInfo.system?.split(' ')[0]
	header['clientType'] = 'student_mini'
	header['channelNo'] = ''
	header['resource'] = ''
	header['phoneType'] = phoneInfo.model
	header['systemVersion'] = phoneInfo.system
	header['teacherId'] = Taro.getStorageSync('teacherId') */
  return config
})

// 响应拦截
request.interceptors.response.use(
  res => {
    console.log(res)
    const Code = res.data.errorCode || "0"
    const Value = res.data.value
    switch (res.status) {
      case "500":
        return
      case "404":
       
    }

    switch (Code) {
      case "401":
        
        
      return res
    }
    if (Code !== "0") {}
    return res
  },
  error => {
    // 错误处理
    if (error && error.response) {
      console.log(error.response)
    }
    return Promise.reject(error)
  }
)

export default request
