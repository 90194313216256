import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide,useSwiper,useSwiperSlide  } from 'swiper/react';
import { useNavigate, useLocation } from 'react-router-dom'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Discount from '../../components/discount'
import PublicBottom from '../../components/publicBottom'
import PublicTabber from '../../components/publicTabber'
import Fixedmodel from '../../components/fixedmodel'
import 'swiper/swiper.min.css';
export default function Package(props) {
	const navigate = useNavigate() // 路由跳转
	
	let [tabList,setTabList] = useState([
		{name:'分销管理',icon:require('./img/davfvcvxc.png'),selectIcon:require('./img/gfdgdfhdfg765.png'),content:['拓展业务，增加销售渠道','分佣管理，精准明细','运营管理商品库存问题'],text:'通过社交裂变快速建立销售渠道，促成交易成功',rightImg:require('./img/wqroirkjmgkf.png')},
		{name:'商品管理',icon:require('./img/asdxcxzg65645.png') ,selectIcon:require('./img/dsfsdf5734.png'),content:['利于商品展示与销售，促成交易','展示商品类型、规格，便于操作'],text:'可以从商品编码，商品分类、商品多规格等参数来管理商品，后期订单关联商品，操作更方便',rightImg:require('./img/fdhghgfhgfh56756756.png')},
		{name:'订单管理',icon:require('./img/daffds323.png'),selectIcon:require('./img/asdxzczx565r6.png'),content:['高效管理商品订单','通过订单数据反馈问题，及时调整方向'],text:'通过移动端查看交易商品的订单状态',rightImg:require('./img/dfgdfgfy546547657.png')},
		{name:'商城报表',icon:require('./img/454dfdst6.png'),selectIcon:require('./img/dsfds56546546.png'),content:['查看数据报表，关注商品售卖情况','提高营业额、销售业绩','周期内的数据分析，订单统计'],text:'清晰反映周期内商城的营业销售量问题',rightImg:require('./img/xzcxzas54234.png')},
		{name:'库存管理',icon:require('./img/aerfaereww67.png'),selectIcon:require('./img/rebfgty6765.png'),content:['查询商品的数量调整规则','统计进出货明细'],text:'查看商品库存调整货品量',rightImg:require('./img/dvxdvgdfste6.png')},
		{name:'支付管理',icon:require('./img/asdasd1231414fvd.png'),selectIcon:require('./img/ewrew4tr.png'),content:['保证交易行为与业务的正规且合理','便于统计营业情况'],text:'商品交易完成支付作业',rightImg:require('./img/thugcvbdfty54654.png')},
		{name:'物流管理',icon:require('./img/dvfdvds566s.png'),selectIcon:require('./img/234fdgdfgjh.png'),content:['追踪物流详情，反馈信息','出发货的轨迹实时查询'],text:'提高搬运作业与运输效率；使接受订货和发出订货更为省力',rightImg:require('./img/fhfgu678678.png')},
		{name:'积分会员',icon:require('./img/asdasd3asd.png'),selectIcon:require('./img/assfda4.png'),content:['吸引用户并进行商品售卖','实现门店获客量与销售业绩','用于裂变营销'],text:'通过积分鼓励用户完成指定行为，促进用户留存、活跃、拉新、转化',rightImg:require('./img/hgjghjgh67867.png')},
	])
	let [changeIndex,setchangeIndex] = useState(0)
	useEffect(()=>{
		
	},[])
	function gotemplate(){
		navigate(`/Templatecenter`)
	}
	return (
		<div className="box">
			<PublicTabber type='1' path='index' />
			<div className='bannerBox'>
				<div className='banner'>
					<div className='bannercenter'>
						<img src={require('./img/bannercenter.png')} />
						<div className='bannermain'>
							<div className='bannermaintop'>
								<img src={require('./img/asdsdcxzadasdas548.png')} alt="" />
								让人人都有自己的小程序
							</div>
							<div className='bannermainmiddle'>
								<div>
								为每个场景定制
								</div>
								<div>
								平价解决方案
								</div>
							</div>
							<div className='bannermainbottom'>
								<div className='bannermainbottomleft' onClick={()=>{
									navigate(`/Fastexperience`)
								}}>了解详情</div>
								{/* <div className='bannermainbottomright' onClick={gotemplate}>查看支持场景列表 
									<img src={require('./img/down.png')}/>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='introduce'>
				<div className='introduceitem'>
					<img src={require('./img/xitong.png')} />
					<div className='introducetitle'>功能丰富的商户系统</div>
					<p>品牌所需功能模块化</p>
					<p>创意场景任意搭配</p>
					<p>兼容多种个性店铺装修</p>
					<p>每月都有新功能</p>
					<div className='introducebottom'>
						<span className='introducenum'>60</span> 
						<span className='introducejia'>+</span> 
						系统数量
					</div>
				</div>
				<div className='introduceitemline'></div>
				<div className='introduceitem'>
					<img src={require('./img/model.png')} />
					<div className='introducetitle'>简约便捷的功能模块</div>
					<p>模板快速创建部署</p>
					<p>随心搭配组件</p>
					<p>营销工具组件风格</p>
					<p>功能搭配可视化</p>
					<div className='introducebottom'>
						<span className='introducenum'>120</span> 
						<span className='introducejia'>+</span> 
						功能模块
					</div>
				</div>
				<div className='introduceitemline'></div>
				<div className='introduceitem'>
					<img src={require('./img/dingyue.png')} />
					<div className='introducetitle'>灵活实惠的订阅方式</div>
					<p>优化研发流程</p>
					<p>价格优惠</p>
					<p>7天无理由退款</p>
					<p>体验阶段完全免费</p>
					<div className='introducebottom'>
						<span className='introducenum'>5</span> 
						<span className='introducejia'>min</span> 
						快速搭建
					</div>
				</div>
				<div className='introduceitemline'></div>
				<div className='introduceitem'>
					<img src={require('./img/chengzhang.png')} />
					<div className='introducetitle'>与品牌共同成长</div>
					<p>持续打磨产品设计</p>
					<p>种子用户享五年费用折扣</p>
					<p>加入众宜客计划</p>
					<p>赚取佣金或会员时长</p>
					<div className='introducebottom'>
						<span className='introducenum'>0</span> 
						<span className='introducejia'>元</span> 
						种子计划
					</div>
				</div>
			</div>
			<div className='Industrystatus'>
				<div className='Industrystatustitle'>
					<h2>行业现状</h2>
					<p>同行商家如何实现破局？如何趋利避害跨步向前！</p>
				</div>
				<div className='Industrystatustop'>
					<div className='Industrystatustopleft'>
						<img src={require('./img/dazvzcxz.png')} />
						<h3 className='Industrystatustoplefttitle'>
						 加盟平台费用高
						</h3>
						<p>平台花销大，效果达不到？</p>
						<p>功能需要调，额外附加费？</p>
						<p>次年继续用，收取费更高？</p>
						<p>营销效益好，抽成高利润？</p>
					</div>
					<img src={require('./img/afvvzvasdw.png')} alt="" />
				</div>
				<div className='Industrystatusbottom'>
					<div className='Industrystatusbottomitem'>
						<img src={require('./img/cadaczxcxzcxz.png')} />
						<h3 className='Industrystatusbottomitemtitle'>营销困难</h3>
						<p>活动接连搞，单量却很少？</p>
						<p>优惠降降降，销转不达标？</p>
					</div>
					<div className='Industrystatusbottomline'></div>
					<div className='Industrystatusbottomitem'>
						<img src={require('./img/adsasascxz.png')} />
						<h3 className='Industrystatusbottomitemtitle'>获客难</h3>
						<p>门店点击率“滑铁卢”？</p>
						<p>无法挽留住新客户？</p>
					</div>
					<div className='Industrystatusbottomline'></div>
					<div className='Industrystatusbottomitem'>
						<img src={require('./img/asdacxzdfetegf.png')} />
						<h3 className='Industrystatusbottomitemtitle'>库存管理混乱</h3>
						<p>不清楚货量？规格？</p>
						<p>容易忘记库存数量？</p>
					</div>
					<div className='Industrystatusbottomline'></div>
					<div className='Industrystatusbottomitem'>
						<img src={require('./img/saqweqd.png')} />
						<h3 className='Industrystatusbottomitemtitle'>流量问题</h3>
						<p>平台软件无法实现热门展示？</p>
						<p>种草内容无法达成曝光？</p>
					</div>
					<div className='Industrystatusbottomline'></div>
					<div className='Industrystatusbottomitem'>
						<img src={require('./img/sadeqwed.png')} />
						<h3 className='Industrystatusbottomitemtitle'>客户成本高</h3>
						<p>新老用户如何开发和维护？</p>
						<p>老客户的利润占比如何定位？</p>
					</div>
				</div>
			</div>
			<div className='h2Title'>
				<h2>解决方案</h2>
				<p>功能的展示多元化来解决实质问题</p>
			</div>
			<div className='programmeBox'>
				<div className='programme'>
					
					<div className='programmemain'>
						<div className='leftTab'>
							{tabList.map((c,i)=>{
								return <div onClick={()=>{
									setchangeIndex(i)
								}} className={`${i==changeIndex?'changeIndex':''}`}><img src={i==changeIndex?c.selectIcon:c.icon} /> {c.name} 
									{i==changeIndex? (<span className='iconfont icon-youjiantou '></span>):''}
									
								</div>
							})}
						</div>
						<div className='rightImg'>
							<img src={tabList[changeIndex].rightImg} />
							<div className='main'>
								<div className='title'>{tabList[changeIndex]?.name}</div>
								<div className='top'>
									{tabList[changeIndex]?.content.map(c=>{
										return <div><span></span>{c}</div>
									})}
								</div>
								<div className='text'>
								{tabList[changeIndex]?.text}
								</div>
							</div>
							<div className='btn' onClick={()=>{
									navigate(`/Fastexperience`)
								}}>立即体验 <i className='iconfont icon-youjiantou'></i></div>
						</div>
					</div>
				</div>
			</div>
			<div className='scene'>
				<div className='h2Title'>
					<h2>适用场景</h2>
					<p>多场景，全链路适配，总有一款适合你</p>
				</div>
				<div className='main'>
					<div>
						<div>
							<img src={require('./img/asd3weqfadf.png')} />
							<p>SHOP</p>
							<div>电子商务</div>
						</div>
					</div>
					<div>
						<div>
							<img src={require('./img/asda3afas.png')} />
							<p>HAR</p>
							<div>美容美发</div>
						</div>
						<div>
							<img src={require('./img/sad34asd.png')} />
							<p>COST</p>
							<div>知识付费</div>
						</div>
					</div>
					<div>
						<div>
							<img src={require('./img/dsvgsd5ydfs.png')} />
							<p>TRAIN</p>
							<div>教育培训</div>
						</div>
						<div>
							<img src={require('./img/wvgsdsg.png')} />
							<p>SIGN</p>
							<div>活动报名</div>
						</div>
					</div>
					<div>
						<div>
							<img src={require('./img/asdxcz134.png')} />
							<p>OPERATE</p>
							<div>私域运营</div>
						</div>
						<div>
							<img src={require('./img/3rdsgsdt.png')} />
							<p>COSTOMER</p>
							<div>客户管理</div>
						</div>
					</div>
					<div>
						<div>
							<img src={require('./img/dewvds5.png')} />
							<p>COST</p>
							<div>场馆预约</div>
						</div>
					</div>
					<div>
						<div>
							<img src={require('./img/asrervd32423.png')} />
							<p>OPERATE</p>
							<div>餐饮服务</div>
						</div>
						<div>
							<img src={require('./img/e5r.png')} />
							<p>COSTOMER</p>
							<div>表单</div>
						</div>
					</div>
					<div>
						<div>
							<img src={require('./img/rew45435dsg.png')} />
							<p>ARTICLE</p>
							<div>文章管理</div>
						</div>
						<div>
							<img src={require('./img/htr2342345.png')} />
							<p>BRAND</p>
							<div>品牌影响</div>
						</div>
					</div>
					<div>
						<div>
							<img src={require('./img/asdsdasdacxzf.png')} />
							<p>PHOTOGRAPHY</p>
							<div>婚纱摄影</div>
						</div>
						<div>
							<img src={require('./img/rgftgdf.png')} />
							<p>ACTIVITY</p>
							<div>促销活动</div>
						</div>
					</div>
					<div>
						<div>
							<span onClick={gotemplate} >查看更多 <i className='iconfont icon-a-youjiantouzhixiangyoujiantou'></i> </span>
						</div>
					</div>
				</div>
			</div>
			<div className='customerBox'>
				<div className='customer'>
					<div className='h2Title'>
						<h2>客户的声音</h2>
						<p>各行各业中小型商户的不二之选</p>
					</div>
					<div className='customermain'>
						<Swiper
							loop
							centeredSlides
							spaceBetween={50}
							slidesPerView={3}
							onSwiper={(swiper) => console.log(swiper)}
							onSlideChange={() => console.log('slide change')}
							>
							<SwiperSlide>
								<img src={require('./img/dfsgfgdfgdfgdf.png')} />
							</SwiperSlide>
							<SwiperSlide>
								<img src={require('./img/hjhgfgvbxx.png')} />
							</SwiperSlide>
							<SwiperSlide>
								<img src={require('./img/fdhgff nbnvb.png')} />
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
			</div>
			<Discount />
			<PublicBottom/>
			<Fixedmodel />
		</div>
	)
}
