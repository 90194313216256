import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import PublicTabber from '../../components/publicTabber'
export default function Aboutus(props) {
	
	
	return (
		<div className="aboutus" >
			<PublicTabber type='1' path="aboutus" />
			<div className='bannerBox'>
				<div className='banner'>
					<img src={require('./img/gfjdhnortyhjrnbgfjgf.png')} alt="" />
				</div>
			</div>
			<div className='contentBox'>
				<div className='content'>
					<div className='title'>
						<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} alt="" />
						众宜简介
					</div>
					<p>
						众宜，名称取自于《庄子·天地》中的“方且应众宜”，对应「众人之所宜」的含义；“宜”作为我们的目标有两重含义，即有适宜的含义，指解决方案能够适用于多种场景；也有便宜的含义，指平价化的定价策略。
						<br/>
						<br/>
						<br/>
						公司成立多年，在低代码、云原生等方面具有深厚的技术积累；在促进线上线下经营理念与方法的深度融合等方面，也具有丰富的经验；在提供全场景、全过程、全周期、全方位的通用性产品及服务的基础上，将逐步地满足更多的个性化需求、边缘需求，主要服务于包括个人、中小企业在内的经营实体，助力于业务效率提升、品牌打造等方面。最终达到每个人都可以低成本、低门槛地按需定制自己的软件产品
					</p>
					<div className='list'>
						<div className='listitem'>
							<img src={require('./img/dghjdgkbndklfjnfd.png')} />
							<p>
								涉及电商、餐饮、美业、场馆、教育及知识付费六大业务体系，精英队伍打造专属模版、服务与陪跑计划
							</p>
						</div>
						<div className='listitem'>
							<img src={require('./img/kjsdnbfkdsgkjsdbgdfkjs.png')} />
							<p>
							全面帮助商家解决在互联网时代所遇到的“流量获取”、“营销模式”、“多域变现”“门店获客与沉淀”等问题
							</p>
						</div>
						<div className='listitem'>
							<img src={require('./img/fedbhjdkasnfbkjds.png')} />
							<p>
							全面支持商家定制化服务需求 ，旨在人人都有专属小程序，轻松实现数字化的个性体验
							</p>
						</div>
					</div>
				</div>
				
			</div>
			<div className='box'>
				<div className='boxcenter'>
					<div className='boxcentertop'>
						<div className='title'>
							<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} alt="" />
							企业愿景
						</div>
						<div className='text'>
						让人人都有自己的小程序
						</div>
					</div>
					<div className='boxcentertop'>
						<div className='title'>
							<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} alt="" />
							企业使命
						</div>
						<div className='text'>
						为中小型企业提供专业、高效、适宜的产品和服务
						</div>
					</div>
				</div>
			</div>
			<div className='Special'>
				<div className='title'>
					<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} alt="" />
					产品特色
				</div>
				<div className='list'>
					<div className='listitem'>
						<img src={require('./img/fbdhdhdgfhjgf.png')} />
						<div className='text'>
						1--5分钟快速创建小程序
						<br/>
						快速搭建完整适配场景
						<br/>
						使用无压力，轻松上手
						</div>
					</div>
					<div className='listitem'>
						<img src={require('./img/asdaisdhasdnasd.png')} />
						<div className='text'>
						拖拽即可搭建小程序
						<br/>
						不限制账号数量
						<br/>
						不同场景安心使用
						</div>
					</div>
					<div className='listitem'>
						<img src={require('./img/hfgjtyutyutyu.png')} />
						<div className='text'>
						建站专家提供免费建站服务
						<br/>
						全方位助力品牌效益增长
						<br/>
						把握流量入口，精细化运营
						</div>
					</div>
					<div className='listitem'>
						<img src={require('./img/drydydfdfydfgf.png')} />
						<div className='text'>
						平台稳定，数据安全
						<br/>
						每月定期迭代新工具
						<br/>
						提供全链路服务体系
						</div>
					</div>
					<div className='listitem'>
						<img src={require('./img/gdfygfhgfhgfh.png')} />
						<div className='text'>
						全包服务更贴心
						<br/>
						多流程驱动业务发展
						<br/>
						商业利益多元化
						</div>
					</div>
					<div className='listitem'>
						<img src={require('./img/dfhgfugfugugf.png')} />
						<div className='text'>
						齐发展
						<br/>
						共进步
						<br/>
						打通全链路双赢局面
						</div>
					</div>
				</div>
			</div>
			<div className='bottomBox'>
				<div className='bottom'>
					<div className='title'>
						<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} alt="" />
						加入我们（品牌合作）
					</div>
					<p>
						如果你怀揣梦想，拒绝将就，不如加入我们，以梦为马，不负韶华，你踏江而来，我许你未来可期!
					</p>
					<div className='contact'>
						{/* <span>商务咨询：1245555@sina .com</span>
						<span>大客户定制：1245555@sina .com</span>
						<span>媒体联络：1245555@sina .com</span>
						<span>代理商：联系建站专家</span> */}
					</div>
				</div>
			</div>
		</div>
	)
}