import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
export default function Discount(props) {
	const [locationCity, setlocationCity] = useState('') // 城市
	const [name, setname] = useState('') // 名称
	const [tel, settel] = useState('') // 电话
	const [wechat, setwechat] = useState('') //微信
	const search = useLocation().search
	// const [search] = useState(location) // 路由传参
	// console.log(location)
	useEffect(()=>{
		
	},[])
	//提交表单
	let putDetail =()=>{
		request({
			url: '/merchant/api/registration/add',
			method: 'POST',
			data: {
				locationCity,
				name,
				tel,
				wechat
			},
		}).then(res=>{
			let { data, errorCode } = res.data
			if (errorCode == 0) {
				props.close()
			}
		})
	}
	return (
		<div className="participatemodel">
				<div className='main'>
					<img src={require('./img/fdfdgdfhbcvcvn.png')} />
					<div className='title'>填写信息，参与众宜客计划</div>
					<div className='inputitem'>
						<i className='iconfont icon-xingming'></i>
						<input type="text" placeholder='姓名' onInput={(e)=>{
							setname(e.target.value)
						}} />
					</div>
					<div className='inputitem'>
						<i className='iconfont icon-shoujihao'></i>
						<input type="text" placeholder='手机号' onInput={(e)=>{
							settel(e.target.value)
						}}/>
					</div>
					<div className='inputitem'>
						<i className='iconfont icon-weixin'></i>
						<input type="text" placeholder='微信号' onInput={(e)=>{
							setwechat(e.target.value)
						}}/>
					</div>
					<div className='inputitem'>
						<i className='iconfont icon-shouhuodizhi'></i>
						<input type="text" placeholder='所在城市' onInput={(e)=>{
							setlocationCity(e.target.value)
						}}/>
					</div>
					<div className='btnBox'>
						<button onClick={props.close}>取消</button>
						<button onClick={putDetail}>提交</button>
					</div>
				</div>
		</div>
	)
}