import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
export default function Discount(props) {
	const [detail, setdetail] = useState({}) // 主页的数据机构
	const search = useLocation().search
    const navigate = useNavigate() // 路由跳转
	// const [search] = useState(location) // 路由传参
	// console.log(location)
	useEffect(()=>{
		
	},[])
	
	return (
		<div className="miniappmodel">
			<div className="miniappmodelmain">
                <div><i className='iconfont icon-cha' onClick={props.close}></i> </div>
				<img src="https://docs.zoe-cloud.com/tmxTt34DfEXRaxwk78NkccFEJbmaWb2i.png" alt="" />
				<p>使用微信扫码一对一咨询</p>
            </div>
		</div>
	)
}