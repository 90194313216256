import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Discount from '../../components/discount'
import PublicBottom from '../../components/publicBottom'
import PublicTabber from '../../components/publicTabber'
import Participatemodel from '../../components/participatemodel'
export default function Customerplan(props) {
    let [modelFlag,setmodelFlag] =useState(false)
    let changeModelFlag = ()=>{
        setmodelFlag(true)
    }
	return (
		<div className="customerplan">
			<PublicTabber type='2' path='Customerplan' />
            <div className='bannerBox'>
				<div className='banner'>
					<div className='bannercenter'>
						<img src={require('./img/tyjtjhnnfgngfn.png')} />
						<div className='bannermain'>
							<div className='bannermaintop'>
                            众宜客计划
							</div>
							<div className='bannermainmiddle'>
                            旨在大中小企业/商户都有专属小程序，全面支持上架推广，携手共进，共创财富
							</div>
							<div className='bannermainbottom'>
								<div className='bannermainbottomleft' onClick={changeModelFlag}>立即参与</div>
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className='rule'>
                <span><i className='iconfont icon-xiaolaba'></i>活动规则：</span>
                1.每邀请一位好友下单，即可拥有推广奖励
                2.成为会员用户即可参加本次活动
            </div>
            <div className='texttitle'>
                <img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} />
                推广产品
            </div>
            <div className='product'>
                <div className='text'></div>
                <div className='typelist'>
                    <div className='typelistitem'>
						<div className='title'>普通会员</div>
						<div className='imgBox'><img src={require('./img/efdd4hbgnf.png')}/></div>
						<p>
							<img src={require('./img/gou.png')} />
							支持公共版小程序
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							支持独立版（个人主体）
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							基础模板选择功能
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							会员专属模板选择
						</p>
						<div className='btn'>分享赚10天</div>
					</div>
					<div className='typelistitem'>
						<div className='title'>高级会员</div>
						<div className='imgBox'><img src={require('./img/efdd4hbgnf.png')}/></div>
						<p>
							<img src={require('./img/gou.png')} />
							支持公共版(无数量限制）
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							支持独立版（个人+商户主体）
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							支持自定义组件
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							客服提供专属指导+专属模板
						</p>
						<div className='btn'>分享赚20天</div>
					</div>
					<div className='typelistitem'>
						<div className='title'>大客户定制</div>
						<div className='imgBox'><img src={require('./img/efdd4hbgnf.png')}/></div>
						<p>
							<img src={require('./img/gou.png')} />
							私有功能
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							定制开发
						</p>
						<p>
							<img src={require('./img/gou.png')} />
							专属客服
						</p>
						<div className='btn'>分享赚100天</div>
					</div>
                </div>
                <div className='btmBtn' onClick={changeModelFlag}>
                    立即参与 <i className='iconfont icon-a-youjiantouzhixiangyoujiantou'></i>
                </div>
            </div>
            <div className='texttitle'>
                <img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} />
                活动详情
            </div>
            <div className='detail'>
                <div className='detailitem'>
                    <div className='num'>1</div>
                    <div>
                        <p>邀请总人数≤5 ，每邀请1人，会员时间延长5天；</p>
                        <p>6≤邀请总人数≤50，每邀请1人，获得邀请现金【邀请现金=实付价*邀请分佣 15%】</p>
                        <p>51≤邀请总人数≤500000，每邀请1人，获得邀请现金【邀请现金=实付价*邀请分佣 20%】</p>
                    </div>
                </div>
                <div className='detailitem'>
                    <div className='num'>2</div>
                    <div>
                        <p>邀请总人数：邀请好友下单即算成功邀请成功，统计累计邀请人数；</p>
                    </div>
                </div>
                <div className='detailitem'>
                    <div className='num'>3</div>
                    <div>
                        <p>通过邀请获得的会员时长需在会员期内使用,过期视为自动放弃权益;</p>
                    </div>
                </div>
                <div className='detailitem'>
                    <div className='num'>4</div>
                    <div>
                        <p>支持7天内无理由退款；</p>
                    </div>
                </div>
                <div className='notes'>注：活动解释权归平台所有；</div>
                <div className='btn' onClick={changeModelFlag}>立即参与 &gt; </div>
            </div>
            <PublicBottom />
            {modelFlag?(<Participatemodel close={()=>{setmodelFlag(false)}} />):''}
            
		</div>
	)
}