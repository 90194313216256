import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Discount from '../../components/discount'
import PublicBottom from '../../components/publicBottom'
import PublicTabber from '../../components/publicTabber'
import { Pagination,ConfigProvider } from 'antd'
import Customermodel from '../../components/customermodel'
export default function Fastexperience(props) {
	useEffect(()=>{
		window.scrollTo(0, 0);
	},[])
	let [flag,setFlag] = useState(false)
	return (
		<div className="fastexperience">
			<PublicTabber type='1' path='productservice' />
			<div className='bannerBox'>
					<div className='banner'>
						<img src={require('./img/gjjghjhgjghutyu.png')} alt="" />
					<div className='content'>
						<div className='title'>
						快速搭建 体验产品
						</div>
						<div className='text'>
							<img src={require('./img/SketchPngd4ea10ca06234fe12a04de3975e3bdcc1cd1f0adb60816c19f051cb6bd61b645.png')} />
							5分钟快速拥有自己的小程序
						</div>
					</div>
				</div>
			</div>
			<div className='main'>
				<img src={require('./img/dasfggdfgdfh.png')} />
				<img src={require('./img/ghjgfhfgfh.png')} />
				<img src={require('./img/asdascxzfdstfdsgdfs.png')} />
				<img src={require('./img/ghjgfhfgfh.png')} />
				<img src={require('./img/fdvhdtgfhdfgdfg.png')} />
			</div>
			<div className='serviceBox'>
				<div className='service'>
					<div className='servicetop'>
						<img src={require('../../publicimg/serviceimg.png')} />
						<div>
							<p>创建企业版本小程序或者更多高级版本定制，请联系客服咨询  </p>
							<div>
								{/* <span>免费咨询电话</span> */}
								{/* 400-111-1111111 */}
							</div>
						</div>
					</div>
					<div className='btnBox'>
						<div className='btn' onClick={()=>{
							setFlag(true)
						}}>
							在线咨询
						</div>
					</div>
				</div>
			</div>
			<Discount />
			<PublicBottom />
			{flag?<Customermodel close={()=>{setFlag(false)}} />:''}
		</div>
	)
}