import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Customermodel from '../../components/customermodel'
export default function Discount(props) {
	const [detail, setdetail] = useState({}) // 主页的数据机构
	const search = useLocation().search
    const navigate = useNavigate() // 路由跳转
	// const [search] = useState(location) // 路由传参
	// console.log(location)
	let [flag,setFlag] = useState(false)
	useEffect(()=>{
		
	},[])
	
	return (
		<div className="discount">
			<div className="discountmain">
                <div style={{color:'#FFDC6D',fontSize:'20px'}}>限时优惠：</div>
                <div style={{color:'#fff',fontSize:'20px'}}>立即试用可获得公共版小程序免费「一年」权益</div>
                <button onClick={()=>{
                    setFlag(true)
                }}>了解详情 &gt; </button>
            </div>
			{flag?<Customermodel close={()=>{setFlag(false)}} />:''}
		</div>
	)
}