import './index.scss'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
export default function Package(props) {
    const navigate = useNavigate() // 路由跳转
    function goPage (path){
        navigate(`/${path}`)
    }
    let openLogin = ()=>{
        window.open("https://zoe-cloud.com/web/",'_blank');  
    }
    let [menuFlag,setMenuFlag] = useState(false)
	return (
		<div className='type2'>
            <div className='tabbarBox'>
                <div>
                    <img className='logo' src={require('./img/logo.png')} />
                    <div className='tabbar'>
                        <div className={props.path=='index'?'changeIndex':''} onClick={()=>goPage('')}>首页</div>
                        <div className={props.path=='productservice'?'changeIndex':''} onMouseEnter={()=>setMenuFlag(true)}>产品服务
                            <span className='iconfont icon-arrow-down'></span>
                            {menuFlag?(
                                <div className='menu' onMouseLeave={()=>setMenuFlag(false)}>
                                    <span onClick={()=>goPage('Fastexperience')}>快速搭建</span>
                                    <span onClick={()=>goPage('Systemfunction')}>功能介绍</span>
                                    {/* <span onClick={()=>goPage('Templatecenter')}>模板中心</span> */}
                                    <span onClick={()=>goPage('ProductEntrance')}>产品入口</span>
                                </div>
                            ):''}
                            
                        </div>
                        <div className={props.path=='ChargingStandard'?'changeIndex':''} onClick={()=>goPage('ChargingStandard')}>定价</div>
                        <div className={props.path=='Customerplan'?'changeIndex':''} onClick={()=>goPage('Customerplan')}>众宜客计划</div>
                        <div className={props.path=='aboutus'?'changeIndex':''} onClick={()=>goPage('Aboutus')}>关于我们</div>
                    </div>
                </div>
                <div className='login' onClick={openLogin}>登录</div>
            </div>
        </div>
	)
}