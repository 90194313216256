import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
export default function Fixedmodel(props) {
	const [detail, setdetail] = useState({}) // 主页的数据机构
	const search = useLocation().search
    const navigate = useNavigate() // 路由跳转
	// const [search] = useState(location) // 路由传参
	// console.log(location)
	let [showFlag,setShowFlag] = useState(true)
	useEffect(()=>{
		
	},[])
	function goTop(){
		let t=setInterval(()=>{
			let y = window.scrollY
			if(y==0) {
				clearInterval(t)
				return 
			}
			window.scrollTo(0, y-=50);
		},10)
		
	}
	return showFlag? ((
		<div className="fixedmodel">
			<div className='close'> <i className='iconfont icon-cha' onClick={()=>{
				setShowFlag(false)
			}}></i> </div>
			<div className='main'>
				<img className='heard' src={require('../../publicimg/serviceimg.png')}/>
				<img className='code' src="https://docs.zoe-cloud.com/tmxTt34DfEXRaxwk78NkccFEJbmaWb2i.png" alt="" />
				<div>扫码咨询</div>
				<button onClick={()=>{
					navigate('/Fastexperience')
				}}>快速体验</button>
				<div onClick={goTop}>
					<img src={require('./img/asdasdas423523523.png')} alt="" />
					<p>回到顶部</p>
				</div>
			</div>
		</div>
	)):''
}