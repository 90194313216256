import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Customermodel from '../customermodel'
export default function PublicBottom(props) {
	const [list, setlist] = useState([
		{title:'产品', list:[{name:'快速搭建',},{name:'功能介绍'},{name:'模板中心'},{name:'价格专享'}]},
		{title:'服务', list:[{name:'整合营销',},{name:'私域运营'},{name:'增长运营'},{name:'个性开发'},{name:'日常交流'},{name:'系统维护'}]},
		{title:'商务合作', list:[{name:'分销业务',},{name:'国际业务'}]},
		{title:'关于我们', list:[{name:'企业介绍',},{name:'品牌介绍'},{name:'联系我们'},{name:'隐私政策'},{name:'用户隐私协议'}]},
		{title:'在线加盟', list:[{name:'商家合作',}]},
		{title:'加入我们', list:[{name:'公司招聘',}]},
	]) // 主页的数据机构
	let [flag,setFlag] = useState(false)
	useEffect(()=>{
		
	},[])
	
	return (
		<div className="publicBottom">
			<div className='publicBottommain'>
				<div className='publicBottommaintop'>
					{/* {list.map(c=>{
						return (
							<div>
								<div className='publicBottommaintoptitle'>{c.title}</div>
								{c.list.map(t=>{
									return <div className='publicBottommaintopcontent'>{t.name}</div>
								})}
							</div>
						)
					})} */}
					<div>
						<div className='publicBottommaintoptitle'>售前咨询热线</div>
						{/* <div className='publicBottommaintopcontent information'>010-12345678</div> */}
						<div className='publicBottommaintopcontent '><button className='btn' onClick={()=>{
							setFlag(true)
						}}>在线咨询</button></div>
					</div>
				</div>
				<div className='publicBottommainline'></div>
				<div className='publicBottommainname'>
					<span>海南博雅世纪科技有限公司</span>
					<span>
						<a href="https://beian.miit.gov.cn" target="_blank">琼ICP备2022001073号</a>
					</span>
					<span>
						<a href="https://docs.zoe-cloud.com/P3eRxW8sGa47yDExpcceG3HdiTDyErEt.html" target='_blank'>用户服务协议</a>
					</span>
				</div>
				<div className='publicBottommainaddress'>
					{/* <div><span className='iconfont icon-dianhua'></span> 公司电话: 010-12345678</div> */}
					<div><span className='iconfont icon-shouhuodizhi'></span> 公司地址：海南省海口市龙华区南海大道15号龙湖海口时代天街2期15#（幢）17层1710号</div>
					<div><span className='iconfont icon-youjian'></span> 电子邮箱: zoe-cloud@zoe-cloud.com</div>
				</div>
			</div>
			{flag?<Customermodel close={()=>{setFlag(false)}} />:''}
		</div>
	)
}