import './index.scss'
import request from '../../utils/request'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Discount from '../../components/discount'
import PublicBottom from '../../components/publicBottom'
import PublicTabber from '../../components/publicTabber'
export default function Systemfunction(props) {
    // ProductEntrance
    const navigate = useNavigate() // 路由跳转
    function jumpPage(){
        navigate('/ProductEntrance')
    }
	return (
		<div className="Systemfunction">
			<PublicTabber type='2' path='productservice' />
            <div className='bannerBox'>
				<div className='banner'>
					<div className='bannercenter'>
						<img src={require('./img/tyjtjhnnfgngfn.png')} />
						<div className='bannermain'>
							<div className='bannermaintop'>
                            支持多场景解决方案
							</div>
							<div className='bannermainmiddle'>
                                线上商城系统、预约系统、课程管理系统、活动报名系统让线上做生意不在难
							</div>
							<div className='bannermainbottom'>
								<div className='bannermainbottomleft'  onClick={()=>{
									navigate(`/Fastexperience`)
								}}>立即试用</div>
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className='list'>
                <div className='listitemBox'>
                    <div className='listitem'>
                        <img src={require('./img/ukghjgfhdfhg.png')} />
                        <div className='line'></div>
                        <div className='listitemmain'>
                            <img src={require('./img/tytyutyu.png')} />
                            <div className='title'>商城系统功能</div>
                            <div className='minlist'>
                                <i></i><span>商品管理，</span>商品的上下架、商品信息有效展示
                            </div>
                            <div className='minlist'>
                                <i></i><span>促销活动，</span>设置各种活动 分销涨粉、增加销
                            </div>
                            <div className='minlist'>
                                <i></i><span>库存管理，</span>数据化、视觉化、智能化 大大提
                            </div>
                            <div className='minlist'>
                                <i></i><span>在线支付，</span>客户线上支付 交易快速、便利
                            </div>
                            <p>线上商城系统是自营+多用户商城系统，商品的进、销、存、调，助你快速创建网上销售系统，卖货更轻松</p>
                            <div className='btn' onClick={jumpPage}>
                                立即体验
                                <span className='iconfont icon-a-youjiantouzhixiangyoujiantou'></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='listitemBox'>
                    <div className='listitem'>
                        <div className='listitemmain'>
                            <img src={require('./img/5tryutyujmh.png')} />
                            <div className='title'>预约系统功能</div>
                            <div className='minlist'>
                                <i></i><span>服务预约，</span>支持多种时间选择方式
                            </div>
                            <div className='minlist'>
                                <i></i><span>排班功能，</span>支持服务关联员工、排班
                            </div>
                            <div className='minlist'>
                                <i></i><span>资源管理，</span>支持资源管理与服务关联
                            </div>
                            <div className='minlist'>
                                <i></i><span>服务定价，</span>支持多维度服务定价以及服务详情
                            </div>
                            <p>预约系统是中小商户及个人提高服务质量及效率的智能化系统，有效提升管理效率</p>
                            <div className='btn' onClick={jumpPage}>
                                立即体验
                                <span className='iconfont icon-a-youjiantouzhixiangyoujiantou'></span>
                            </div>
                        </div>
                        <div className='line'></div>
                        <img src={require('./img/yhjhfjhjh.png')} />
                    </div>
                </div>
                <div className='listitemBox'>
                    <div className='listitem'>
                        <img src={require('./img/thjuityuutyutyu.png')} />
                        <div className='line'></div>
                        <div className='listitemmain'>
                            <img src={require('./img/r3345refhghgfjffgj.png')} />
                            <div className='title'>课程管理系统</div>
                            <div className='minlist'>
                                <i></i><span>排课功能，</span>支持排课、约课、打卡、签到
                            </div>
                            <div className='minlist'>
                                <i></i><span>课程上传，</span>支持语音以及视频课程的上传
                            </div>
                            <div className='minlist'>
                                <i></i><span>详情展示，</span>支持专栏以及课程详情页的展示
                            </div>
                            <p>课程管理系统是知识付费行业、教育机构的好帮手，线上开课更便捷</p>
                            <div className='btn' onClick={jumpPage}>
                                立即体验
                                <span className='iconfont icon-a-youjiantouzhixiangyoujiantou'></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='listitemBox'>
                    <div className='listitem'>
                    <div className='listitemmain'>
                            <img src={require('./img/retre23423refew.png')} />
                            <div className='title'>活动报名系统</div>
                            <div className='minlist'>
                                <i></i><span>收集核销，</span>支持活动报名的收集与核销
                            </div>
                            <div className='minlist'>
                                <i></i><span>报名提醒，</span>报名提醒，支持活动报名的提醒
                            </div>
                            <div className='minlist'>
                                <i></i><span>报名详情，</span>支持活动报名的详情展示
                            </div>
                            <p>活动报名系统是快速进行组织活动、通知活动、活动核销的首选</p>
                            <div className='btn' onClick={jumpPage}>
                                立即体验
                                <span className='iconfont icon-a-youjiantouzhixiangyoujiantou'></span>
                            </div>
                        </div>
                        <div className='line'></div>
                        <img src={require('./img/yutyuityuitity.png')} />
                    </div>
                </div>
            </div>
            <Discount />
            <PublicBottom />
		</div>
	)
}